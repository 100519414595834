import * as React from "react";
import { Link } from "gatsby";
import * as style from "./Boring.style.module.scss";

const BoringPage = () => {
  const [rickRoll, setRickRoll] = React.useState(false);
  const [giveUpButton, setgiveUpButton] = React.useState("I give up");

  return (
    <main className={style.main}>
      <h1>Boring, sad page</h1>
      <p>
        This is the most boring page on the Internet. Maybe not. Find all hidden
        items and options.
      </p>
      <p className={style.h}>
        You discovered the hidden text! Keep looking because this page has many
        surprises. 7
      </p>
      <button
        onClick={() => {
          alert("You discovered a hidden button. I'm impressed 4");
        }}
        className={style.b}
      >
        This is not a button 2
      </button>
      <input
        type="number"
        className={style.a}
        placeholder="8"
        onChange={(e) => {
          if (e.target.value === "7248") console.log("2485");
          if (e.target.value === "2485") {
            setRickRoll(true);
            setgiveUpButton("never gonna give you up");
            if (typeof window !== "undefined") {
              localStorage.setItem("rickroll", "true");
            }
          }
        }}
      />
      {rickRoll && (
        <iframe
          className={style.video}
          src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      <Link className={style.party_button} to="/playground">
        {giveUpButton}
      </Link>
    </main>
  );
};
export default BoringPage;

export function Head() {
  return <title>Bartłomiej Nowak - frontend developer | Boring Page</title>;
}